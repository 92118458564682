@use "~theme/colors" as *;
@use "~theme/breakpoints" as *;
@use "~theme/mixins" as *;
@use '~theme/typography' as *;


#conditional-insights-parameters-container {
    position: relative;
    padding: 12px 0 12px;
    height: auto;

    .conditional-insights-drive-status-component {
        margin: 12px;
    }

    .conditional-insight-status-message {
        font-family: $font-family-light;
        font-size: 14px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px;
        color: #696969;
        text-align: center;
    }

    .conditional-kpi-section-chart-list {
        display: grid;
        grid-template-columns: 1fr;
        margin: 12px;
        /* Two column layout */
        gap: 20px;
        overflow-x: auto;
        width: 100%;

        .kpi_status_title {
            @include unselectable;
            display: flex;
            align-items: center;

            span:first-of-type {
                margin-right: 5px;
                margin-left: 5px;
            }

            b {
                padding-top: 2px;
            }
        }
    }

    .highcharts-tooltip {
        span {
            .custom-trend-tooltip {
                background: rgba(255, 255, 255, 0.7);
            }
        }
    }

    .conditional-kpi-section-chart-item {
        padding: 20px;
        border: 1px solid #dbdbdb;
        border-radius: 4px;
        /* Additional styling for chart items */
        .highcharts-no-data {
            top: 100px !important;
        }
    }

    .conditional-kpi-section-chart-item:has(.popout-chart-row--condition-insights) {
        padding: 0 20px 20px 20px;
    }

    /* Media query for smaller screens */
    @media screen and (max-width: 768px) {
        .conditional-kpi-section-chart-list {
            grid-template-columns: 1fr;
            /* One column layout */
        }
    }

    .conditional-insight-kpi-list {
        & .highcharts-no-data {
            top: 52px !important;
            height: 336px;
        }
    }
}