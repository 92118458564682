@use '~theme/colors' as *;
@use '~theme/mixins' as *;

.pt-detailed-information-container .asset-and-powertrain-filter-container {

	.toggle-button-container {
		min-height: 32px;

		.toggle-button {
			width: 124px;
			font-size: 14px;
			line-height: 16px;
		}
	}

	.search-field {
		margin-top: 16px;
	}

	.gateway-type-filter {
		width: 250px;
		margin-top: 12px;
	}

	.condition-index-filter {
		width: 120px;
	}

	.condition-based-filter {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 16px;
		width: 250px;

		.condition-statuses {
			display: flex;
			align-items: center;
			margin-left: 10px;
			cursor: pointer;

			.condition-status {
				@include create-border($silver);
				padding: 1px;
				height: 24px;
				width: 24px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: 6px;

				&.selected {
					@include create-border($dodger-blue);
					background-color: $pattens-blue;
				}
			}
		}
	}
}
