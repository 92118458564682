@use "~theme/colors" as *;
@use "~theme/mixins" as *;
@use "~theme/typography" as *;
@use "~theme/breakpoints" as *;

#assistance-trend-container .measurement-list-container {
    
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin: 0px;

    .measurement-list {
        @include create-border;
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: $white;
        padding: 16px;
        margin: 6px 0;

        .measurement-list-title {
            @include font-medium-text-lg;
            color: $nero;
            line-height: 24px;
            padding-left: 8px;
            padding-bottom: 16px;
            display: flex;
            justify-content: space-between;
            
            svg {
                margin: 0 10px 0 10px;
                cursor: pointer;
            }
        }
        .measurment-list-buttons {
            display: flex;
        }
        .signal-group-header-info {
            display: flex;
            color: $dove-gray;
            font-size: 13px;

            span {
                padding-right: 10px;
                padding-top: 5px;
            }
        }

        .measurement-list-body {
            overflow: auto;
            max-height: calc(var(--vh) * 100 - 365px);
        }

        .measurement-list-head {
            & .measurement-list-item { 
                @include font-medium-text-md;
                border-bottom: 1px solid $nero;
                color: $dove-gray;
                &:hover {
                    cursor: auto;
                    background-color: $white;
                }
            }
            & .data-value {
                position: relative;
                right: 7px;
            }
        }

        .measurement-list-item {
            @include font-regular-text-md;
            @include shadow-inset-alto;
            display: flex;
            align-items: center;
            padding: 12px 8px;
            line-height: 18px;
        
            &:hover {
                background-color: $concrete;
                cursor: pointer;
            }
            &.selected-measurement-list-item {
                background-color: $light-blue;
            }
            
            .data-command-checkbox{
                width: 6%;
                svg{
                    padding: 0px,16px,0px,16px;
                }
            }
            .data-label {
                width: 45%;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                svg {
                    margin: 0px 8px 0px 8px;
                }

                .condition-icon {
                    svg {
                        margin: 0;
                    }
                }
                &__unit {
                    color: $dove-gray;
                    border-left: 2px solid rgba(0,0,0,0.12);
                    padding-left: 8px;
                    margin-left: 20px;
                    margin-right: 30px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: flex-start;
                }
            }

            .condition-icon {
                width: 16px;
                margin-left: 0;
                margin-right: 10px;
            }

            .data-value {
                overflow-wrap: break-word;
                width: 25%;
            }

            .data-command {
                text-align: right;
                width: 100%;
                >span {
                    margin: 0 10px 0 10px;
                }

                svg {
                    cursor: pointer;
                }
            }
        }
    }

    @include customScreenMaxWidth(1440) {
        width: 100%;

        .measurement-list {
            width: 100%;
            height: initial;
        }
    }
}