@use '~theme/colors' as *;
@use '~theme/typography' as *;
@use '~theme/zIndexes' as *;

.general-filter-container {
	.general-filter-tippy {
		.tooltip-content {
			label {
				font-family: "ABBvoice-Bold";
				font-weight: bold;
			}
		}
	}

	.general-filter-menu {
		min-width: 80px;
		height: 32px;
		max-width: 200px;
		margin: 9px 0;
		padding: 0 8px;
		font-size: 14px;
		text-align: left;
		border-color: $alto;
		border-radius: 4px;
		background-color: $alto;
		display: flex;
		align-items: center;
		gap: 5px;

		span {
			font-family: "ABBvoice-Bold";
			max-width: 150px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}

.footer_container {
	display: flex;
	justify-content: space-between;
}