@use '~theme/typography' as *;
@use '~theme/_colors' as *;


.edit-muted-asset-container {
    margin-left: 25px;

    .bulk-edit-info-message {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        padding: 8px 24px 8px 10px;
        background-color: rgb(173, 189, 230);
        margin: 0px 0px 10px 0px;
        border-radius: 3px;

        svg {
            height: 20px;
            margin-bottom: 2px;
        }
    }

    .edit-muted-asset-description {
        @include font-regular-text-md;
    }

    .notification-group-selection,
    .period-selection {
        @include font-regular-text-md;

        .section-title {
            margin-top: 20px;
            margin-bottom: 10px;
            @include font-bold-text-md;
            display: flex;

            .title-text {
                margin-left: 10px;
            }

            .select-all {
                &__link {
                    color: $dodger-blue;
                    cursor: pointer;
                    @include font-regular-text-md;
                    text-decoration: none;
                }
            }
        }
    }

    .notification-group-selection {
        .section-title {
            justify-content: space-between;
        }
    }

    .notification-group-selection {
        .notification-groups-list {
            display: flex;

            .column {
                min-width: 150px;
                max-width: 250px;

                .mute-notification-group {
                    display: flex;
                    margin-bottom: 4px;
                    align-items: center;

                    .custom-checkbox {
                        margin-left: 25px;
                    }

                    .group-name {
                        margin-left: 5px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .period-selection {
        .mute-period {
            margin-left: 60px;
            margin-bottom: 15px;

            .time-selector-row-content {
                justify-content: flex-start;
            }
        }
    }
}

.edit-muted-asset-popup {
    &.general-dialog-container {
        &.modal {
            .general-dialog {
                .modal-content {
                    border-radius: 8px;
                    border-top: 4px solid $cod-gray;

                    .modal-header {
                        border-top: none;
                    }
                }
            }
        }
    }
}