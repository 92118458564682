@use "~theme/colors" as *;
@use "~theme/typography" as *;
@use '~theme/mixins' as *;

.powertrain-wizard-modal.modal {
    .general-dialog {
        width: 960px;

        .modal-content {
            min-height: 580px;
            min-width: 960px;

            .modal-header {
                .powertrain-wizard-title {
                    color: $silver;

                    .active {
                        color: $dodger-blue;
                    }

                    .inactive {
                        color: $silver;
                    }

                    .completed {
                        color: $black;
                    }
                }

                .btn-close {
                    width: 14px;
                    height: 14px;
                }
            }

            .modal-body {
                .step1 {
                    padding-top: 30px;

                    .powertrain-wizard-user-guide-row {
                        padding-top: 20px;
                    }

                    .powertrain-wizard-user-guide-text {
                        @include font-regular-text-lg;
                    }

                    #powertrain-name-label {
                        padding-top: 32px;
                    }

                    #powertrain-organization-label {
                        padding-top: 20px;
                    }

                    #powertrain-site-label {
                        padding-top: 20px;
                    }

                    .control-label {
                        @include font-regular-text-md;
                        color: $dove-gray;
                    }

                    .control-item {
                        width: 264px;

                        #powertrain-name {
                            .input {
                                .input-container {
                                    width: 240px;
                                }
                            }
                        }
                    }

                    .organization-select {

                        .group-label-row {
                            height: 20px;
                            display: flex;
                            line-height: 19px;
                            margin-bottom: 10px;

                            &:hover {
                                cursor: pointer;
                            }

                            &.expand {
                                margin-bottom: 0;

                                .group-icon {
                                    transform: rotate(90deg);
                                }
                            }

                            .group-label-title {
                                @include font-medium-text-md;
                                margin-left: 12px;
                                line-height: 16px;
                                margin-top: 2px;
                            }
                        }

                        .general-select__menu-list {
                            padding-left: 5px;
                        }
                    }
                }

                .step2 {

                    .assets-filtered-text {
                        padding-top: 25px;
                    }

                    .powertrain-wizard-user-guide-text {
                        @include font-regular-text-lg;
                    }

                    .text-medium-large {
                        @include font-medium-text-lg;
                    }
                }

                .step3 {

                    .overview-content {
                        padding-top: 26px;

                        .control-label {
                            @include font-regular-text-md;
                            color: $dove-gray;
                        }

                        .control-item {
                            .input {
                                .input-container {
                                    width: 240px;
                                }
                            }
                        }

                        #powertrain-organization-label,
                        #powertrain-site-label {
                            padding-top: 20px;
                        }

                        .powertrain-assets-table {
                            min-width: 640px;
                            min-height: 434px;
                            max-height: 434px;
                            @include create-border($alto);

                            .table-title-row {
                                padding-top: 20px;

                                .text-medium-large {
                                    @include font-medium-text-lg;
                                }
                            }

                            .asset-table-row {
                                padding-top: 32px;
                                min-height: 365px;
                            }
                        }
                    }
                }
            }
        }
    }
}