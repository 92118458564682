@use '~theme/colors' as *;
@use '~theme/mixins' as *;
@use '~theme/typography' as *;
@use "~theme/breakpoints" as *;

$selection-bg: #DEE6FF;

%sticky-style {
    position: sticky;
    left: 0;
    z-index: 3;
    background-color: $white;
    flex: 0 0 340px;
}

%reset-row-padding {
    padding: 0;
}

%cell-stretch-center {
    align-self: stretch;
    display: flex;
    align-items: center;
}

%horzontal-scroll-active {
    box-shadow: inset -10px 0 8px -8px $silver-transparent;
}

.overview-scrollable-list {
    height: -moz-calc(100% - 115px);
    height: -webkit-calc(100% - 115px);
    height: calc(100% - 115px);
    overflow: auto;
    scroll-behavior: smooth;

    .scrollable-list_empty-label {
        text-align: center;
    }

    .list-item {
        min-width: 1300px;

        &.assets {
            .header-item--first-cell {
                padding-left: 16px;
            }
        }
    }

    &.with-filter {
        height: -moz-calc(100% - 205px);
        height: -webkit-calc(100% - 205px);
        height: calc(100% - 205px);

        @include customScreenMaxWidth(1092) {
            height: calc(100% - 337.34px);
        }
    }

    .asset-list-header {
        @extend %reset-row-padding;
        height: 50px;
        display: flex;
        align-items: center;
        position: sticky;
        top: 0;
        z-index: 4;
        background-color: $white;
        border-bottom: 1px solid $black;

        .header-item {
            width: 100%;
            display: flex;
            color: $dove-gray;
            text-transform: uppercase;
            font-weight: bold;
            font-family: $font-family-regular;
            font-size: 12px;
            gap: 8px;
            flex: 1 1 240px;

            .info-icon {
                cursor: pointer;
            }

            .info-button-with-online-help {
                text-transform: none;
                font-weight: 100;
            }

            .info-button-with-online-help .icon_info {
                margin: 0 !important;
            }

            &--cell-fixed {
                position: sticky;
                left: 0;
                z-index: 3;
                background-color: $white;
                flex: 0 0 340px;
                padding-left: 52px;
                @extend %cell-stretch-center;
            }

            &.horizontal-scroll-active {
                @extend %horzontal-scroll-active;
            }
        }
    }

    .item {
        border-bottom: 1px solid $alto;
        cursor: pointer;

        &.active {
            background-color: rgba(51, 102, 255, 0.16);

            .cell-fixed {
                background-color: $selection-bg;
            }
        }

        .cell-fixed {
            @extend %sticky-style;
            @extend %cell-stretch-center;
            display: flex;
            padding-right: 16px;


            &.horizontal-scroll-active {
                @extend %horzontal-scroll-active;
            }

        }

        .cell {
            flex: 1 1 240px;

            &--first-cell {
                padding-left: 16px;
            }
        }
    }

    .open-close-container {
        padding: 0;
        margin: 0 !important;
    }

    .powertrain {
        &__cell-fixed {
            position: sticky;
            left: 40px;
            flex: 0 0 300px;
            z-index: 3;
            background-color: $white;

            &.horizontal-scroll-active {
                box-shadow: inset -10px 0 8px -8px $silver-transparent;
            }
        }

        &__cell {
            flex: 1 1 240px;
        }
    }

    .item,
    .title-content {
        @include font-regular-text-md;
        @extend %reset-row-padding;
        line-height: 27px;
        display: flex;
        width: 100%;
        align-items: center;

        .ci-summary {
            display: flex;
            align-items: center;
        }

        .item-name {
            display: flex;
            align-items: center;
            word-break: break-word;
            align-self: stretch;

            .item-icon {
                display: flex;
                align-items: center;
                margin-right: 10px;
            }

            .name {
                min-height: 27px;
                text-align: left;
            }
        }

        .condition-indices {
            display: flex;
            gap: 8px;

            .signals-status {
                display: flex;
                align-items: center;
            }
        }

        .limit-Summary {
            display: flex;
            gap: 8px;

        }

        .condition-index {
            display: flex;
            padding: 10px 0;
            align-items: center;

            svg {
                margin-right: 10px;
            }

            .kpis {
                .nr-of-kpis {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }

            &__group {
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }

            .kpi-list {
                margin: 3px;

                .kpi-list-item {
                    min-height: 22px;
                    display: flex;

                    svg {
                        margin-top: 2px;
                    }

                    p {
                        margin: 0px;
                    }
                }
            }
        }

        .nr-by-condition-index {
            display: inline-flex;
            gap: 5px;
            flex-wrap: wrap;

            div {
                align-content: center;
            }

            div:not(:last-child) {
                padding-right: 5px;
                border-right: 1px solid $alto;
            }
        }
    }

    .expandable-component {
        .header {
            border-bottom: 1px solid $alto;
            background-color: white;
            padding-left: 0;

            .title-content {
                line-height: 27px;
                @include font-regular-text-md;

                .open-close-container.enable-sticky {
                    justify-content: center;
                    align-self: stretch;
                }
            }
        }

        .expandable-content {
            .asset-list-of-asset-group {
                .item {
                    background-color: $wild-sand;

                    &.active {
                        background-color: rgba(51, 102, 255, 0.16);

                        .cell-fixed {
                            background-color: $selection-bg;
                        }
                    }

                    .item-status {
                        padding: 4px 12px;
                        margin-right: 8px;
                    }

                }
            }

            .no-available-assets {
                text-align: center;
                @include font-regular-text-md;
                line-height: 27px;
                padding: 6px;
                background-color: $wild-sand;
                color: $dove-gray;
            }

            .cell-fixed {
                background-color: $wild-sand;

                .item-name {
                    padding-left: 16px;
                }
            }
        }
    }

    &__render {
        .scrollable-list-with-loading {
            display: none;
        }
    }
}

.resizable-layout {
    display: flex;
    height: 100%;
}


.resize-handle {
    width: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 200px;

    &__button {
        cursor: col-resize;
        background-color: transparent;
        border: 0;

        &:hover {
            cursor: col-resize;
        }
    }
}

.render-condition {
    display: flex;
    align-items: center;
}