@use "~theme/colors" as *;
@use '~theme/mixins' as *;
@use '~theme/typography' as *;

%empty-results-style {
    @include font-regular-text-md;
    color: $dove-gray;
    padding-top: 40px;
    text-align: center;
}

%apply-ellipsis-style {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
}

.connected-assets {
    &__header {
        margin: 16px 0;
        display: flex;
        flex-direction: column;
    }

    .header {
        &__search-wrapper {
            width: 100%;
            position: relative;

            .search-box {
                position: absolute;
                left: 0;
                top: 8px;
                width: 40%;
            }
        }

        &__tab-wrapper {
            width: 100%;

            .tab-component {
                position: relative;

                .tab-header {
                    position: absolute;
                    right: 0;
                    top: -60px;
                }

                .tab-content {
                    background: transparent;
                    height: auto;
                    margin-top: 60px;
                }
            }
        }
    }

    .drives-list {
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 345px);
        overflow: hidden;

        &__body {
            overflow-x: auto;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }

        &__header,
        &__row {
            display: flex;
            flex-shrink: 0;
        }

        &__row {
            border-bottom: 1px solid $alto;
        }

        &__header {
            position: sticky;
            top: 0;
            z-index: 4;
            @include font-medium-text-md;
            color: $dove-gray;
            background-color: $white;
            text-transform: uppercase;

            .drives-list__cell {
                border-bottom: 1px solid $black;
            }
        }

        &__cell {
            padding: 10px;
            min-width: 120px;
            flex: 1 1 250px;
            display: flex;
            @include font-regular-text-md;
            display: flex;
            align-items: center;

            .cell-wrap {
                display: flex;
                align-items: center;

                &__label {
                    margin-left: 12px;
                }
            }

            &--sticky,
            &--sticky-right {
                position: sticky;
                z-index: 1;
                box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.1);
            }

            &--sticky {
                left: 0;
                z-index: 2;
                border-right: 1px solid $black;
                flex: 0 0 250px;
                background-color: $white;
                display: flex;
                align-items: center;

                .drive-name {
                    display: flex;
                    align-items: center;

                    &__wrap {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        margin-left: 8px;
                        width: 100%;
                        align-items: flex-start;
                        text-align: left;
                    }

                    &__text {
                        @include font-regular-text-md;
                        color: $mine-shaft;
                        @extend %apply-ellipsis-style;
                    }

                    &__slno {
                        @include font-regular-text-sm;
                        color: $dove-gray;
                        @extend %apply-ellipsis-style;
                    }
                }

            }

            &--sticky-right {
                right: 0;
                z-index: 2;
                box-shadow: -2px 0px 2px rgba(0, 0, 0, 0.1);
                background-color: $white;
                border-left: 1px solid $black;
                flex: 1 1 25px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .empty-drive-list {
            @extend %empty-results-style;
        }
    }

    .environment-sensor-list {
        height: calc(100vh - 345px);
        overflow: hidden;
        display: flex;
        flex-direction: column;

        &__body {
            overflow-x: auto;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            .empty-environment-sensor-list {
                @extend %empty-results-style;
            }
        }

        &__header,
        &__row {
            display: flex;
        }

        &__header {
            border-bottom: 1px solid $black;
            position: sticky;
            top: 0;
            z-index: 4;
            @include font-medium-text-md;
            color: $dove-gray;
            background-color: $white;
            text-transform: uppercase;
        }

        &__row {
            border-bottom: 1px solid $alto;
        }

        &__cell {
            padding: 10px;
            flex: 1 1 200px;
            display: flex;
            @include font-regular-text-md;

            .environment-name {
                display: flex;
                align-items: center;

                &__wrap {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    width: 100%;
                    align-items: flex-start;
                    text-align: left;
                }

                &__text {
                    @include font-regular-text-md;
                    color: $mine-shaft;
                    @extend %apply-ellipsis-style;
                }

                &__slno {
                    @include font-regular-text-sm;
                    color: $dove-gray;
                }
            }
        }
    }
}

.asset-info {
    &__primary {
        display: flex;
        flex-direction: column;
    }

    &__secondary {
        border-top: 1px solid $alto;
        padding-top: 16px;
        margin-top: 12px;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
    }

    &__col {
        display: flex;
        flex-direction: column;
        @include font-medium-text-md;
        margin-right: 12px;
        width: 48%;

        &:last-child {
            margin-right: 0;
        }
    }

    &__value {
        width: 100%;
        border-radius: 6px;
        border: 2px solid #d1cfcf;
        height: 32px;
        background-color: $alto;
        padding: 3px 6px;
        @extend %apply-ellipsis-style;
    }

    &__modal {
        .general-button.discreet {
            border: 1px solid $dodger-blue;
            background-color: $dodger-blue;
            color: $white;
        }
    }

}

.general-dialog-container.modal .general-dialog>.modal-content .modal-body {
    padding: 0 16px;
}