@use "~theme/colors" as *;
@use '~theme/mixins' as *;
@use '~theme/typography' as *;

%common-section-style {
    background-color: $white;
    border-radius: 8px;
}

.gateway-management-wrapper {
    background-color: $wild-sand;
    padding-left: 32px;
    height: 100%;

    .gateway-management {
        display: flex;
        height: 100%;

        .resize-handle {
            align-items: center;
            margin-top: 0;
        }

        &__left-pane {
            @extend %common-section-style;
            display: flex;
            flex-direction: column;
            padding: 16px;
            margin: 16px 0;
            height: calc(100vh - 134px);
            min-width: 200px !important;
        }

        &__right-pane {
            @extend %common-section-style;
            margin: 16px 16px 16px 0;
            padding: 16px;
            height: calc(100vh - 134px);
        }

        .gateway-title {
            @include font-medium-text-md;
            color: $nero;
            padding-bottom: 20px;

            .title-content {
                display: inline-block;
                border-bottom: 2px solid $dodger-blue;
                padding-bottom: 6px;
            }

            .title {
                padding-left: 4px;
            }
        }

        .list-header {
            margin-bottom: 16px;
            display: flex;
            justify-content: space-between;

            &__left-content {
                width: calc(100% - 72px);
                display: flex;
            }

            &__right-content {
                display: flex;
                justify-content: flex-end;
            }

            .info-icon {
                display: flex;
                align-items: center;
            }

            .filter-icon {
                border: none;
                background-color: transparent;
                cursor: pointer;
                padding: 0;
                width: 32px;
                height: 32px;
                border-radius: 4px;

                &--active {
                    background: rgba(0, 81, 255, 0.08);
                }
            }

            .search-box {
                width: 100%;
                min-width: 100%;
                margin-right: 8px;
            }
        }

        .filter-section {
            &__legend {
                @include font-regular-text-md;
                padding: 4px 0;
            }

            &__btn {
                margin-top: 16px;
                margin-bottom: 8px;
                display: flex;
                justify-content: flex-end;
            }
        }

        .right-pane {
            &__header {
                width: 100%;
                display: flex;
                height: 40px;
            }

            &__contents {
                margin-top: 16px;
            }
        }

        .header {
            &__left-content {
                font-family: $font-family-regular;
                width: 50%;

                .title {
                    font-size: 20px;
                    padding-left: 6px;
                }
            }

            &__right-content {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .connect-cloud-btn {
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    background: rgba(0, 81, 255, 0.08);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-bottom: 3px;
                }

                .connected-cloud {
                    color: $dove-gray;
                    margin-left: 8px;
                }
            }
        }
    }

    .gateway-list-management {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        height: calc(100% - 112px);

        .gateway-list {
            margin: 0;
            padding: 0;
            list-style: none;

            &__item {
                display: flex;
                justify-content: space-between;
                padding: 8px 6px;
                @include font-medium-text-md;
                border-radius: 6px;
                cursor: pointer;

                &:hover {
                    background-color: $alto;
                }

                &--header {
                    padding-bottom: 16px;
                    margin-bottom: 16px;
                    border-bottom: 1px solid $alto;
                }

                &--active {
                    background-color: rgba(51, 102, 255, .16);
                }
            }

            &__empty-label {
                @include font-regular-text-md;
                color: $dove-gray;
            }

            .content-left {
                width: calc(100% - 24px);
                display: flex;
                align-items: center;

                .gateway-name {
                    padding-left: 8px;
                }
            }

            .info-icon {
                display: flex;
                align-items: center;
            }
        }

        .gateway-details {
            display: flex;
            flex-direction: column;

            &__name {
                padding-left: 8px;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                word-break: break-word;

            }

            &__serialno {
                padding-left: 8px;
                @include font-regular-text-sm;
                color: $dove-gray;
            }
        }
    }

    .gateway-content-details {
        margin-top: 16px;

        .tab-component.default .tab-content {
            height: auto;
        }
    }

    .empty-gateway {
        display: flex;
        flex-direction: column;
        @include font-regular-text-md;
        color: $dove-gray;

        &__title {
            padding-bottom: 12px;
            text-align: center;
            margin-top: 24px;
        }

        &__description {
            text-align: center;
        }
    }

    .tab-container .tab-content {
        height: auto;
    }
}

.pt-overview-component .pt-overview-content .pt-overview-children .general-page-content.tools-gateway-management>.tab-container>.tab-content {
    padding-left: 0;
}

.pt-overview-component {
    .pt-overview-content {
        .pt-overview-children {
            height: 100%;

            .general-page-content.tools-gateway-management {
                height: 100%;

                &>.tab-container {
                    height: 100%;

                    &>.tab-content {
                        height: 100%;
                    }
                }
            }
        }
    }
}