@use '~theme/colors'as *;
@use '~theme/mixins'as *;
@use '~theme/typography' as *;
@use '~theme/breakpoints' as *;

.asset-overview {
    margin: 6px 0 0 0;
    position: relative;
    min-height: 14vh;
    height: 100%;
    width: 100%;
    @media (max-width: 1280px) {
        overflow-y: auto;
    }
    
    &__header {
        background-color: #f5f5f5;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
    }
    &__header {
        padding: 16px;
    }
    &__header-row {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    &__heading {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: $font-family-medium;
        font-size: 20px;
        max-width: 56%;
        word-break: break-all;
        margin-bottom: 0;
        margin-right: 8px;
        &-name {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            word-break: break-all;
        }
        @media (max-width: 1280px) {
            width: 54%;
        }
    }
    &-details {
        height: calc(100% - 119px);
        height: -moz-calc(100% - 119px);
        height: -webkit-calc(100% - 119px);
        &.with-filter {
            height: calc(100% - 212px);
            height: -moz-calc(100% - 212px);
            height: -webkit-calc(100% - 212px);
        }
    }
    &__asset-icon {
        margin-right: 6px;
    }
    &__details {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-top: 8px;
        & li {
            margin-right: 10px;
            font-family: $font-family-regular;
            font-size: 14px;
            display: inline-block;
           &:after {
            content: "";
            border-right: 1px solid rgba(0, 0, 0, 0.16);
            margin-left: 10px;
           }
           &:last-child {
            &:after {
                border-right: 0;
            }
           }
        }
    }
    &__asset {
        padding: 4px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        margin-right: 12px;
    }
    &__view-asset {
        margin-left: auto;
    }
    &__asset-online {
        background-color: rgba(0, 81, 255, 0.08); 
    }
    &__asset-offline {
        background-color: rgba(0, 0, 0, 0.08);
    }
    &__online-text {
        font-family: $font-family-medium;
    }
    &__last-measurement {
        font-size: 12px;
    }

    &__connection-type {
        font-size: 14px;
        margin-bottom: 4px;
    }
    &__tags {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    &__tag {
        display: inline-block;
        background: rgba(219, 219, 219, 1);
        padding: 2px 8px 4px 8px;
        font-size: 14px;
        margin: 4px 0 0 0;
        border-radius: 24px;
        color: rgba(31, 31, 31, 1);
        margin-right: 6px;
        line-height: 24px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
    }

}

.asset-overview-params {
    &__container {
        position: relative;
        min-height: 14vh;
        height: 100%;
        width: 100%;
        overflow-y: auto;
        scrollbar-gutter: auto;
        margin-top: 10px;
        height: -webkit-calc(100% - 183px);
        height: -moz-calc(100% - 183px);
        height: calc(100% - 183px);
    }
    &__label {
        height: -webkit-calc(100% - 235px);
        height: -moz-calc(100% - 235px);
        height: calc(100% - 235px);
    }
    &__header {
        background-color: rgba(0, 0, 0, 0.08);
        padding: 8px 16px;
        border-bottom: 1px solid rgba(186, 186, 186, 1);
    }
    &__heading {
        font-family: $font-family-medium;
        font-size: 16px;
        margin-bottom: 0;
    }
    &__list {
        padding: 8px 8px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        display: flex;
        &:last-child {
            border-bottom: 0;
        }
    }
    &__kpi, &__description {
        width: 50%;
        font-size: 14px;
    }
    &__kpi-icon {
        margin-right: 10px;
    }
}

.asset-overview-params-header {
    height: 32px;
    display: flex;
    padding: 6px 16px;

    .header-item {
        width: 100%;
        display: flex;
        color: $dove-gray;
        text-transform: uppercase;
        font-weight: bold;
        font-family: $font-family-regular;
        font-size: 12px;

        .info-icon {
            margin-left: 5px;
            margin-top: -1px;
            cursor: pointer;
        }

        .column-icon {
            margin-right: 5px;
            margin-top: -2px;
            margin-left: -5px;
        }

        .info-button-with-online-help {
            text-transform: none;
            font-weight: 100;
        }
    }
}

.render-no {
    &__asset-limits {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 20px 0;
    }
    &__condition-indices {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 20px 0;
    }
}

.render-good {
    &__condition {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 20px 0;
    }
}

.tippy-tooltip {
    overflow-wrap: break-word;
}