@use '~theme/typography' as *;
@use '~theme/mixins' as *;
@use '~theme/colors' as *;

.data-logger {
    &__sub-heading {
        font-family: $font-family-medium;
    }
    &__type {
        font-size: 14px;
    }
    &__type-separator {
        margin: 12px 0;
    }
    &__history-tooltip {
        background: transparent;
        border: 0;
        margin-left: 8px;
    }
    &__list-container {
        position: relative;
        & .loading-anchor {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 80%;
        }
    }
}

.data-logger-modal.general-dialog-container.modal {
    & .general-dialog {
        max-width: 640px;
    }
    & .general-dialog > .modal-content .general-dialog-header .general-dialog-title {
        font-size: 20px;
        font-family: $font-family-medium;
    }
    & .disclaimer-text {
        font-family: $font-family-regular;
    }
    & .general-disclaimer-container {
        background: rgba(255, 162, 0, 0.08);
        border-top: 0;
        box-shadow: none;
        margin: 12px 0;
    }
    .general-dialog > .modal-content .modal-footer .dialog-accept-button {
        margin-right: 18px;
    }
    & .disclaimer-text {
        white-space: pre-line;
    }
    & .toggle-button-container {
        & button[disabled] {
            pointer-events: none;
            opacity: .6;
        }
    }
}