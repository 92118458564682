@use '~theme/colors' as *;
@use '~theme/mixins' as *;
@use '~theme/typography' as *;
@use '~theme/zIndexes' as *;

.general-filter-modal-container.modal {
	z-index: $general-dialog-zIndex;
	$general-filter-modal-left-pos: 450px;


	&:before {
		height: initial;
		vertical-align: initial;
	}

	#general-filter-modal.modal-dialog {
		margin-top: 52px; // navbar height + bottom border
		margin-right: 231px;
		width: 500px;

		.modal-header {
			padding-top: 32px;
			padding-bottom: 0px;
			border-bottom: none;

			.modal-title {
				@include font-bold-text-lg;
				color: $black;
				line-height: 18.75px;
				display: flex;

				.info-button-with-online-help {
					.icon_info {
						margin-top: 0.1px;
						margin-bottom: 0;
					}
				}
			}
		}

		.modal-content {

			.custom-checkbox .checkbox {
				border-radius: 6px;
			}

			div::-webkit-scrollbar {
				width: 5px;
				background-color: $wild-sand;
			}

			div::-webkit-scrollbar-thumb {
				border-radius: 10px;
				box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
				-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
				background-color: $alto;
			}

			div {
				scrollbar-color: $alto $wild-sand;
			}

			color: $cod-gray;
			border-right: 6px solid $dodger-blue;

			.modal-header {
				padding-left: 24px;
				padding-right: 24px;

				.general-filter-collapse-btn {
					transform: rotate(180deg);

					&:hover {
						cursor: pointer;
						opacity: 0.8;
					}
				}
			}

			.modal-body {
				padding: 14px 26px 0px 26px;

				.filter-view-content {
					height: 300px;
					overflow-y: auto;
					overflow-x: clip;
					margin-right: -15px;
				}

				.filter-view-container.container {
					.loading-anchor {
						height: 70px;

						.loading-wrapper {
							height: 70px;

							.loading-centered {
								height: auto;
							}
						}
					}

					.error-component {
						width: 100%;
						height: auto;
						margin: initial;
					}

					.active-filters-label {
						font-family: $font-family-bold;
						font-size: 15px;
					}

					.row {
						height: 40px;
						padding-left: 15px;
						align-items: center;

						&.available-filters-label {
							@include font-bold-text-md;
							line-height: 20px;
							border-bottom: 1px solid $alto;
							color: $cod-gray;
							word-break: break-all;
							padding-right: 12px;
						}

						&.filter-text {
							@include font-regular-text-md;
							color: $cod-gray;
							border-bottom: 1px solid $alto;
							word-break: break-all;
							padding-right: 12px;
							line-height: 16px;
						}
					}
				}
			}
		}

		.modal-footer {
			padding: 16px 26px 24px 26px;
			border-top: none;

			.general-button {
				margin-left: 8px;
			}

			.info-button-with-online-help {
				.icon_info {
					margin-top: 6px;
				}
			}
		}
	}
}

.general-filter-modal-backdrop.modal-backdrop {
	z-index: $general-dialog-zIndex;
}