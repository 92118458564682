@use "~theme/colors" as *;
@use "~theme/breakpoints" as *;

.cross-asset-chart-container {
    display: flex;
    flex-direction: column;
    max-height: calc(var(--vh) * 100 - 280px);
    height: calc(var(--vh) * 100 - 280px);

    @include xl-min-h {
        max-height: calc(var(--vh) * 100 - 210px);
        height: calc(var(--vh) * 100 - 210px);
    }

    .chart-wrapper-row {
        height: inherit;
    }

    .chart-container {
        .highcharts-no-data {
            @include xl-max-w {
                right: 0px !important;
                margin: 0 auto;
                width: 60%;
            }
        }

        .chart-style {
            @include md-max-h {
                min-height: 500px;
            }
        }
    }

    .highcharts-tooltip {
        span {
            .custom-trend-tooltip {
                background: rgba(255, 255, 255, 0.7);
            }
        }
    }
}

#cross-analysis-scatter-chart,
#cross-analysis-scatter-chart-pop-out {
    .scatter-tooltip {
        font-size: 14px;
        line-height: 20px;

        .scatter-tooltip-header {
            font-weight: bold;
            line-height: 16px;
            padding-bottom: 8px;
            margin-bottom: 8px;
            border-bottom: $alto solid 1px;
        }
    }
}