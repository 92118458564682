@use '~theme/colors' as *;
@use '~theme/mixins' as *;
@use '~theme/typography' as *;

.error-status-bar {
	background: $pomegranate;
}

.warning-status-bar {
	background: $orange-peel;
}

.notification-status-bar {
	@include unselectable;
	display: block;
	float: left;
	top: 20px;
	width: calc(100% - 50px);
	background: $silver-chalice;

	.notification-status-bar-text {
		@include font-medium-text-lg;
		margin-left: 12px;
	}

	&.error-status-bar {
		background: $pomegranate;
		@include font-medium-text-lg;
		padding: 14px 0;
	}

	&.renewal-status-bar,
	&.Free-status-bar {
		background: $pomegranate;
		@include font-medium-text-lg;
		padding: 14px 0;
	}

	&.warning-status-bar {
		background: $orange-peel;
		@include font-medium-text-lg;
		padding: 14px 0;
	}

	&.success-status-bar {
		background: $jungle-green;
		@include font-medium-text-lg;
		padding: 14px 0;
	}

	&.info-status-bar {
		background: $dodger-blue;
		@include font-medium-text-lg;
		padding: 14px 0;
	}

	.link {
		color: $white;
		padding: 5px;
		text-decoration: underline;

		:hover {
			color: $white;
			cursor: pointer;
		}
	}

	div {
		float: left;

		&:first-of-type {
			width: calc(100% - 36px);
			color: $white;
			line-height: 18px;
			text-align: left;
			padding: 0px;
			display: flex;
			flex-direction: row;

			svg {
				align-self: center;
			}

			span {
				align-self: center;
			}
		}

		&:last-of-type {
			padding-left: 12px;
			cursor: pointer;
		}

		.notification-icon-container {
			width: auto;
			cursor: auto;
		}
	}

	#closeNotifyButton {
		padding-left: 6px;

		svg {
			position: absolute;
			top: 50%;
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
		}
	}
}