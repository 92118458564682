@use '~theme/colors' as *;
@use '~theme/mixins' as *;
@use '~theme/typography' as *;
@use "~theme/breakpoints" as *;

.gateway-info {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: initial;
    margin-top: 16px;

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        margin-right: 16px;

        &:last-child {
            margin-right: 0;
        }
    }

    &__lists {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
    }

    &__item {
        display: flex;
        justify-content: space-between;
        @include shadow-inset-alto;
        width: 100%;
        padding: 12px 8px 12px 8px;

        .data {
            &__label {
                @include font-medium-text-md;
            }

            &__value {
                @include font-bold-text-md;
            }
        }
    }

    @include customScreenMaxWidth(1440) {
        flex-direction: column;
    }
}