.gateway_asset_icon_offline {
    background-color: rgba(0, 0, 0, 0.08);
}

.gateway_asset_icon_online {
    background-color: #3366FF29;
}

.gateway_asset_icon {
    padding: 6px;
    border-radius: 50%;
    display: inline-block;
    border: 0;
}