@use "~theme/colors" as *;

.highcharts-tooltip {
    span {
        font-size: 14px;

        .condition-indicator-tooltip {
            padding: 15px;
            background-color: $white;
            border-width: 1px;
            border-style: solid;
            border-color: $mercury;
            box-shadow: 1px 1px $alto;
            width: fit-content;

            b {
                color: $tundora;
            }

            .t-item {
                padding-top: 2px;

                span {
                    &.symbol {
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                    }

                    &.type {
                        margin-left: 12px;
                        font-family: 'ABBvoice-Bold';
                        font-size: 14px;
                        font-style: normal;
                    }

                    &.kpi-type {
                        margin-left: 12px;
                    }
                }

            }

            .t-header {
                font-weight: bold;
                font-size: 14px;
                margin-bottom: 10px;
                border-bottom: $alto solid 1px;
            }
        }

        .custom-trend-tooltip {
            background: rgba(255, 255, 255, 0.7);
        }
    }
}

.popout-chart-modal {  
    .modal-dialog {
      .modal-content {
        .modal-body {
          .popout-chart-container {
            .popout-chart-row--condition-insights{
               
                .right-button-group{
                    .reset-zoom-button{
                        position: relative;
                        bottom: 12px;
                    }
                  
                }
            }
          }
        }
      }
    }
  }