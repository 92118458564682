@use '~theme/typography' as *;
@use '~theme/_colors' as *;

.bulk-header {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 24px 8px 10px;
    background: #0051FF14;
    margin: 0px 0px 10px 0px;
    border-radius: 3px;

    svg {
        height: 20px;
        margin-bottom: 2px;
    }

    .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.bulk-error-icon {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 24px 8px 10px;
    background-color: rgb(230, 173, 178);
    margin: 0px 0px 10px 0px;
    border-radius: 3px;

    svg {
        height: 20px;
        margin-bottom: 2px;
    }
}

.select-assets {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0px var(--layoutpadding06) 0px var(--layoutpadding06);
    gap: 10px;
    height: 376px;
}

.bulk-muted-asset-modal.modal {
    & .general-dialog>.modal-content {
        & .general-dialog-header .general-dialog-title {
            font-size: 20px;
        }
    }

    &.general-dialog-container.modal .general-dialog {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.select-assets {
    .muted-assets {
        border: 1px solid $alto;
        width: 50%;
        text-align: left;
        padding: 10px;
        border-radius: 4px;
        overflow: auto;

        &__head {
            font-family: $font-family-bold;
            margin-top: 8px;
            margin-bottom: 12px;
            font-size: 16px;
        }

        .mute-asset-list {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            overflow-wrap: break-word;

            .asset-list-bulk {
                white-space: nowrap;
                max-width: 300px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .no-selected-asset {
            margin-top: 10px;
            font-size: 14px;
            color: $silver-chalice;
        }
    }

    .select-mute-assets {
        border: 1px solid $alto;
        width: 50%;
        padding: 10px;
        text-align: left;
        border-radius: 4px;

        .search-mute {
            height: 43px;
            padding: 5px 0;

            .search-field {
                width: 100%;
                border-radius: 4px;
            }
        }

        &__head {
            font-family: $font-family-bold;
            margin-top: 8px;
            margin-bottom: 12px;
            font-size: 16px;
        }

        .mute-asset-list-container {
            .all-assets-list {
                max-height: 238px;
                overflow: auto;

                & .mute-asset-list {
                    & .custom-checkbox {
                        margin-bottom: 2px;
                    }
                }
            }

            .mute-asset-list {
                display: flex;
                align-items: center;
                overflow-wrap: break-word;
                margin-top: 5px;
                gap: 5px;
                padding-left: 4px;

                &:hover {
                    background-color: $alto;
                }

                &.select-all {
                    border-bottom: 1px solid $alto;
                    padding-bottom: 3px;
                }

                .custom-checkbox {
                    & .checkbox {
                        border-radius: 8px;
                    }
                }

                .mute-check-box {
                    height: 28px;

                    .custom-checkbox {
                        .checkbox {
                            border-radius: 8px;
                        }
                    }
                }

                .asset-icon-container {
                    margin-bottom: 2px;
                    margin-left: 4px;
                }

                .asset-list-bulk {
                    white-space: nowrap;
                    max-width: 280px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-left: 4px;
                    font-family: $font-family-regular;

                    &--select-all {
                        font-family: $font-family-bold;
                        margin-top: 2px;
                        cursor: pointer;
                    }
                }

                .checkbox-content {
                    cursor: pointer;
                }
            }
        }

        .no-matches-found {
            margin-top: 10px;
            font-size: 14px;
            color: #a0a0a0;
        }
    }
}