/*
00–19: Elements and Components
20–29: Element and Component Drop Downs
30–39: Secondary Navigation
40–49: Header / Footer
50–59: Primary Navigation
60–69: Full Screen Features
70–79: Special Cases
80–89: Modals / Dialog Windows
90–99: Notifications
*/

//ELEMENTS AND COMPONENTS
//PowertrainAssestsOverview/CardComponent
$dropdown-icon-zIndex: 7;
//GeneralSelect
$general-select__menu-zIndex: 8;
//FastScrollButton
$button-zIndex: 10;
//WithLoadingStatus
$loading-wrapper-zIndex: 12;
//DateSelector
$date-selector-container-zIndex: 13;
//ContextMenu
$context-menu-list-zIndex: 14;
//GeneralFilter
$sidebar-zIndex: 20;

//HEADER
//HeaderComponent
$pt-navbar-container-zIndex: 40;

//MODALS
//GeneralDialog, LoadingWrapper
$general-dialog-zIndex: 80;
//BulkUpload
$highchart-tooltip-zIndex: 85;
$dialog-second-level-zIndex: 86;
//Minimum resolution/width modal
$min-res-modal-zIndex: 88;

//NOTIFICATIONS
//GeneralNotification, Portal
$notification-zIndex: 90;