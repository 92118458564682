@use "~theme/colors" as *;

.user-management-wrapper {
    background-color: $wild-sand;
    height: calc(100% - 1.67px);
    padding: 16px;
}

.pt-overview-component .pt-overview-content .pt-overview-children .general-page-content.tools-user-management>.tab-container>.tab-content {
    padding-left: 0;
}

.pt-overview-component {
    .pt-overview-content {
        .pt-overview-children {
            height: 100%;

            .general-page-content.tools-user-management {
                height: 100%;

                &>.tab-container {
                    height: 100%;

                    &>.tab-content {
                        height: 100%;
                    }
                }
            }
        }
    }
}