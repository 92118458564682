@use '~theme/colors' as *;
@use '~theme/mixins' as *;
@use '~theme/typography' as *;

.pt-detailed-information-container .asset-and-powertrain-filter-container .asset-and-powertrain-list-container {
    @include font-regular-text-md;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    border-top: 1px solid $mine-shaft;
    height: 100%;
    line-height: 16px;
    margin-top: 24px;
    width: 250px;

    .asset-list {
        display: flex;
        flex-direction: column;

        .asset-list-item {
            @include shadow-inset-alto;
            display: flex;
            align-items: center;
            padding: 8px;
            min-height: 40px;
            cursor: pointer;
            overflow-wrap: break-word;

            &:hover {
                background-color: $concrete;
            }

            .asset-icon-container {
                width: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .asset-name {
                width: calc(100% - 38px);
                padding: 0px 8px;
                word-break: break-all;
                @include font-regular-text-md;
                margin: 0;
                overflow: unset;
                white-space: pre-wrap;
            }

            &.selected {
                background-color: $light-blue;
            }
        }
    }

    .powertrain-list {
        display: flex;
        flex-direction: column;

        .expandable-component {
            .expandable-content {
                .asset-list {
                    width: calc(100% - 24px);
                    margin-left: 24px;
                }
            }

            .header {
                @include shadow-inset-alto;
                background-color: $wild-sand;
                padding: 8px;
                min-height: 40px;

                .open-close-container {
                    margin-left: 0px;
                    margin-right: 10px;
                }

                .title-content {
                    .powertrain-list-item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .powertrain-list-item-name {
                            padding-right: 8px;
                            word-break: break-all;
                            overflow: unset;
                            white-space: pre-wrap;
                            text-align: left;
                            line-height: 16px;

                        }
                    }
                }
            }
        }
    }

    .no-matches-found {
        color: $mine-shaft-transparent;
        padding: 12px;
        border-bottom: 1px solid $alto;
    }
}