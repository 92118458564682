@use '~theme/colors' as *;
@use '~theme/typography' as *;

#general-filter-modal.modal-dialog .filter-edit-container {
	&.container {
		padding: 0px;

		.filter-form-group {
			margin-bottom: 20px;
		}

		.filter-form-label {
			@include font-regular-text-md;
			color: $dove-gray;
			margin-bottom: 2px;
		}
		.row.main-section-row {
			padding-top: 12px;
			margin-top: 16px;
			background-color: $wild-sand;
			border-radius: 4px;

			.country-selector, .site-selector, .asset-group-selector {
				.general-select__menu .general-select__menu-list .general-select__option {
					@include font-regular-text-md;
					line-height: 16px;
				}
			}

			.sub-section-row {
				display: flex;
			}
		}
		.general-filter-selector-container {
			.general-select__option {
				display: flex;

				.custom-checkbox {
					margin: 0 4px 0 0;
				}
				label {
					margin: 0 0 0 6px;
					word-break: break-word;
				}
			}
			.general-select__menu {
				@include font-medium-text-md;
				.site-type-icon {
					margin-left: 4px;
					margin-right: 4px;
				}
			}
		}

		.general-select__value-container {
			flex-direction: row-reverse;

			.selected-number {
				padding-left: 8px;
			}
		}
	}
}
