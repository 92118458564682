@use "~theme/colors" as *;
@use "~theme/mixins" as *;
@use "~theme/typography" as *;
@use "~theme/zIndexes" as *;

.header-component {
    @include unselectable;
    height: 51px;
    @include shadow-inset-alto;
    position: relative;

    .navbar {
        padding-bottom: 0px;
        padding-top: 0;
        font-size: 16px;
        line-height: 25px;
    }

    .red-line {
        position: fixed;
        width: 30px;
        height: 4px;
        top: 12px;
        background: $red;
    }

    .pt-navbar-container {
        @include font-regular-text-lg;
        z-index: $pt-navbar-container-zIndex;
        border-radius: 0px;
        background-color: $white;
        padding-left: 16px;
        line-height: 24px;
        height: 50px;

        .navbar-brand {
            @include font-medium-subtitle;
            line-height: 24px;
            padding: 0;
            margin-top: 8px;
        }

        .navbar-nav {
            cursor: pointer;
            margin-top: 8px;

            .nav-link {
                border-bottom: 2px solid transparent;
            }
        }

        .navbar-nav .active {
            border-bottom: 2px solid $dodger-blue;
            pointer-events: none;
        }

        .nav-item {
            @include font-regular-text-lg;
            font-weight: 500;
            font-style: normal;
            text-decoration: none;
            color: $mine-shaft;
            width: inherit;
            height: inherit;
        }

        .pt-navbar-right {
            position: fixed;
            right: 10px;

            &.navbar-nav {
                margin-top: 0;
            }

            .user-dropdown-menu {
                border-bottom: 2px solid transparent;
                display: flex;
                align-items: center;

                .icon-div {
                    display: flex;
                    flex-direction: row;
                }

                .dropdown-toggle {
                    background-color: white;
                    border-color: white;
                    border-radius: 0;
                    box-shadow: none;
                    border: none;
                    padding: 12px 16px;
                }

                &.show {
                    border-bottom: 2px solid $dodger-blue;
                }
            }

            .help-menu {
                display: flex;
                align-items: center;
                border-bottom: 2px solid transparent;

                a {
                    padding: 12px 16px;
                }
            }

            .dropdown-toggle::after {
                display: none;
            }

            .right-dropdown-menu {
                display: flex;
                align-items: center;
                text-align: center;
                padding: 0px;
                border-bottom: 2px solid transparent;

                .dropdown-toggle {
                    background-color: white;
                    border-color: white;
                    border-radius: 0;
                    box-shadow: none;
                    border: none;
                    padding: 12px 16px;
                }

                &.show {
                    border-bottom: 2px solid $dodger-blue;
                }
            }

            .dropdown-toggle::after {
                display: none;
            }

            .right-dropdown-menu {
                text-align: center;
                padding: 0px;

                .dropdown-toggle {
                    background-color: white;
                    border-color: white;
                    border-radius: 0;
                    box-shadow: none;
                }

                .dropdown-toggle::after {
                    display: none;
                }

                .dropdown-menu {
                    @include font-regular-text-md;
                    width: 240px;
                    max-width: 240px;
                    transform: none;
                    margin: 0;
                    top: 52px;
                    right: -2px;
                    left: auto;
                    background-color: $white;
                    animation: none;

                    .dropdown-item {
                        border: none;
                        padding-left: 16px;
                        white-space: normal;
                    }

                    .dropdown-item.active {
                        background-color: $pattens-blue;
                        color: $mine-shaft;
                    }

                    .dropdown-item:hover {
                        background-color: $gallery;
                        color: $mine-shaft;
                    }
                }
            }

            .user-dropdown-menu {
                text-align: center;
                padding: 0px;

                &.show {
                    >a {
                        border-bottom: 2px solid $dodger-blue;
                    }
                }

                .dropdown-toggle::after {
                    display: none;
                }

                .dropdown-menu {
                    @include font-regular-text-md;
                    min-width: 240px;
                    transform: none;
                    margin: 0;
                    top: 52px;
                    right: -2px;
                    left: auto;
                    background-color: $white;
                    animation: none;

                    .dropdown-item {
                        padding-left: 16px;
                    }

                    .dropdown-item.active {
                        background-color: $pattens-blue;
                        border-bottom: none;
                        color: $mine-shaft;
                    }

                    .dropdown-item:hover {
                        background-color: $gallery;
                        color: $mine-shaft;
                    }

                    .user-name-email {
                        cursor: default;
                        line-height: 16px;
                        padding: 5px 16px 10px 16px;

                        .user-name {
                            @include font-bold-text-md;
                        }

                        .user-email {
                            font-weight: 400;
                            @include font-regular-text-sm;
                        }
                    }
                }
            }

            .notification-dropdown-menu {
                border-bottom: 2px solid transparent;
                display: flex;
                align-items: center;

                .dropdown-toggle {
                    background-color: white;
                    border-color: white;
                    border-radius: 0;
                    box-shadow: none;
                    border: none;
                    padding: 12px 16px;
                }

                &.show {
                    border-bottom: 2px solid $dodger-blue;
                }

                &.show {
                    >a {
                        border-bottom: 2px solid $dodger-blue;
                    }
                }

                .dropdown-toggle::after {
                    display: none;
                }

                .dropdown-menu {
                    @include font-regular-text-md;
                    min-width: 240px;
                    transform: none;
                    margin: 0;
                    top: 50px;
                    right: -2px;
                    left: auto;
                    background-color: $wild-sand;
                    animation: none;
                    width: 400px;
                    max-height: 90vh;
                    border-right: 4px solid $dodger-blue;
                    border-radius: 8px 0 0 8px;
                    margin-top: 2px;
                    cursor: initial;
                    padding-bottom: 0px;

                    .dropdown-item {
                        padding-left: 0px;
                    }

                    .dropdown-item:hover {
                        background-color: $wild-sand;
                    }

                    .dropdown-item:active {
                        color: inherit;
                    }
                }

                .notification-title {
                    font-weight: 700;
                    font-size: 16px;
                }

                .configure-notification {
                    font-weight: 500;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    color: $black;
                }

                .empty-notification-text {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    color: $dove-gray;
                    text-align: center;
                }

                .notification-icons-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .notification-badge {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $white;
                    border-radius: 100%;
                    font-size: 8px;
                    width: 20px;
                    height: 18px;

                    &.black-badge {
                        background-color: $black;
                    }

                    &.primary-badge {
                        background-color: $dodger-blue;
                    }
                }

                .notification-item {
                    background-color: $white;
                    border-radius: 4px;
                    box-shadow: 0px 30px 50px 0px $alto-transparent;
                    min-height: 70px;
                    max-height: 90px;
                    width: 370px;

                    &:hover {
                        background-color: $hover;
                    }

                    &:active {
                        background-color: $clicked;
                    }
                }

                .notification-asset-type,
                .notification-time-condition {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                }

                .notification-description {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    line-clamp: 2;
                }

                .notification-list-container {
                    -ms-overflow-style: none;
                    /* IE and Edge */
                    scrollbar-width: none;
                    /* Firefox */
                    display: block;
                    overflow-y: auto;
                    max-height: 70vh;

                    .scrollable-list {
                        overflow-y: unset;
                    }
                }
            }
        }
    }
}