@use "~theme/typography" as *;
@use "~theme/breakpoints" as *;
@use "~theme/colors" as *;

.drive-status-chart-legend {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding-top: 25px;

  .legend-item {
    display: flex;
    align-items: start;
    height: fit-content;
    flex-basis: 24%;

    @include xl-max-w {
      width: 50%;
    }


    .status-column {
      height: 100%;
      padding-top: 4px;

      .status-block {
        width: 16px;
        height: 16px;
        border-radius: 4px;
        margin-right: 8px;
      }
    }

    .text-content {
      display: flex;
      flex-direction: column;

      .title {
        @include font-bold-subtitle;
        font-size: 18px;
      }

      .description {
        padding-top: 4px;
        @include font-regular-text-md;
      }
    }
  }
}

.popout-chart-modal {
  margin-left: 0%;
  margin-top: 0%;
  height: 100%;
  width: 100%;

  .modal-dialog {
    .modal-content {
      .modal-body {
        .popout-chart-container {
          justify-content: space-between;

          #drivestatus-chart-pop-out {
            height: 50%;
          }
        }
      }
    }
  }
}

.popout-chart-modal {
  .modal-dialog {
    .modal-content {
      .modal-body {
        .popout-chart-container {
          #popout-chart-row-button-container {
            .left-button-group {
              .dive-status-title--no-data {
                display: flex;
                align-items: center;

                .info-button-with-online-help {
                  .icon_info {
                    margin-bottom: 0;
                  }
                }
              }

              .kpi_status_title {
                display: flex;
                gap: 7px;

                b {
                  margin-top: 2px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.highcharts-tooltip {
  span {
    font-size: 14px;

    .custom-drive-chart-tooltip {
      padding: 5px;
      border-width: 1px;
      border-style: solid;
      border-color: $mercury;
      box-shadow: 1px 1px $alto;
      z-index: 9999;

      b {
        color: $tundora;
      }

      .t-item {
        padding-top: 2px;

        span {
          margin-left: 5px;
          font-size: 14px;
          font-weight: normal;

          &.symbol {
            display: inline-block;
            width: 10px;
            height: 10px;
            //background-color: red
          }
        }
      }

      .t-header {
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 2px;
        border-bottom: $alto solid 1px;
      }
    }
  }
}

.drive-status-icon {
  margin-right: 5px;
  margin-bottom: 2px;
}

.drive-status-title {
  padding-top: 5px;
}

.drive-status__chart {
  .popout-chart-row--condition-insights {
    .right-button-group {
      display: flex;
      align-items: center;
      justify-content: center;

      .popout-button {
        margin-bottom: 0px;
        position: relative;
        left: 10px;
      }
    }
  }

  .drive-status-title {
    margin-bottom: 36px;
  }

  .dive-status-title--no-data {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  .tippy-popper {
    left: 63px !important;
  }

  .tippy-arrow {
    left: 128px !important;
  }

  .info-button-with-online-help {
    .icon_info {
      margin-bottom: 0;
    }
  }

  .highcharts-no-data {
    height: 210px;
  }
}