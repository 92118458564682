@use '~theme/typography' as *;
@use '~theme/_colors' as *;
@use "~theme/breakpoints" as *;


.muted-assets-container {
    margin: 16px;

    .muted-assets-description {
        @include font-regular-text-md;
        color: $dove-gray;
    }

    .muted-assets-table-container {

        .asset-name-with-checkbox,
        .muted-assets-name-column {
            display: flex;

            .custom-checkbox {
                margin-right: 5px;
                margin-top: -2px;
            }

            .asset-name-label {
                margin-left: 16px;
            }

            .asset-icon-container {
                margin-left: 16px;
                margin-right: 6px;
            }
        }

        .general-table-title {
            .muted-assets-name-column {
                align-items: center;

                .custom-checkbox {
                    .checkbox {
                        margin-left: 0px;
                    }
                }
            }
        }

        .action-button-section {
            float: right;
            @include font-bold-text-md;
            margin-right: 8px;



            button {
                border: none;
                background-color: transparent;
                padding: 0px;

                svg {
                    margin-right: 20px;
                    margin-top: -2px;
                }
            }
        }
    }

    .rc-table-content {
        table {
            width: 100%;

            .general-table-title {
                cursor: default;
            }
        }
    }

    .muted-assets-bulk-operations {
        height: 45px;
        display: flex;
        justify-content: space-between;

        .assets-selected {
            margin-top: 5px;
            margin-left: 16px;
        }

        .general-button {
            margin-left: 15px;

            @include customScreenMaxWidth(1220) {
                margin-left: 6px;
                padding-left: 6px;
                padding-right: 6px;
            }
        }

        .search-mute {
            .search-field {
                width: 300px;

                @include customScreenMaxWidth(1220) {
                    width: 100%;
                }
            }
        }

        .notification-settings {
            &__left-pane {
                display: flex;

                @include customScreenMaxWidth(1220) {
                    width: 150px;
                }
            }
        }
    }

    .general-button,
    .assets-selected {
        @include font-medium-text-md;
    }

    .action-button-section {
        .general-button {
            @include customScreenMaxWidth(1220) {
                padding-left: 6px;
                padding-right: 6px;
            }
        }
    }
}



.edit-muted-asset-popup {
    .general-dialog-title {
        svg {
            margin-top: 8px;
        }
    }

    &.bulk-muted-asset-modal {
        .modal-content {
            height: 615px;

            .bulk-edit-info-message {
                display: flex;
                align-items: flex-start;
                gap: 10px;
                padding: 8px 24px 8px 10px;
                background-color: rgb(173, 189, 230);
                margin: 0px 0px 10px 0px;
                border-radius: 3px;

                svg {
                    height: 20px;
                    margin-bottom: 2px;
                }
            }
        }

        &.general-dialog-container {
            &.modal {
                .general-dialog {
                    .modal-content {
                        border-radius: 8px;
                        border-top: 4px solid $cod-gray;

                        .modal-header {
                            border-top: none;
                        }
                    }
                }
            }
        }
    }



    .general-dialog-container.modal .general-dialog>.modal-content {
        border-radius: 8px;
    }
}

.edit-muted-asset-popup .general-dialog .modal-content {
    min-height: 200px;
}

.edit-muted-asset-popup .general-dialog .modal-content {
    width: 800px;
    min-width: 750px;
}