@use "~theme/colors" as *;

.limits-multi-select {
    max-width: 400px;
    min-width: 300px;
    max-height: 32px;
    text-align: left;
    top: -2px;
    font-size: 14px;
    font-weight: 400;

    .op-limits__control {
        min-height: 36px;
        max-height: 36px;
        border-radius: 0;
        border-color: $silver;

        .op-limits__indicator {
            padding: 4px 6px;
        }
    }
    &.active {
        .op-limits__control {
            border-color: #2684ff;
            box-shadow: 0 0 0 1px #2684ff;
        }
    }

    .op-limits__value-container {
        min-height: 32px;
        max-height: 32px;
        padding: 0 8px;
    }

    .op-limits__multi-value {
        border-radius: 16px;

        .custom-checkbox {
            display: none;
        }

        .op-limits__multi-value__remove {
            &:hover {
                background-color: inherit;
                border-radius: 20px;
            }
        }
    }

    .op-limits__menu {
        .op-limits__option.op-limits__option--is-selected {
            background-color: $white;
            color: $black;
        }

        .op-limits__group {
            border-bottom: 2px solid $alto;

            .op-limits__option {
                padding: 4px 12px;
            }
        }
    }
}