
#op-trend-chart {
    .highcharts-tooltip {
        span {
            .custom-trend-tooltip {
                background: rgba(255, 255, 255, 0.7);
            }
        }
    }

    .highcharts-no-data {
        height: calc(100% - 60px);
        height: -webkit-calc(100% - 60px);
        height: -webkit-calc(100% - 60px);
    }
}