@use '~theme/colors' as *;
@use '~theme/mixins' as *;
@use '~theme/typography' as *;

.event-log-filter-content {
    @include unselectable;
    @include font-regular-text-md;
    background-color: $wild-sand;
    height: auto;
    max-width: unset;

    .filter-area {
        padding-bottom: 10px;
        padding-top: 5px;

        .event-log-filter {
            padding-left: 9px;
            padding-right: 2px;
        }

        .row {
            color: $dove-gray;
            padding: 5px 0px;

            .search-field {
                width: 240px;
                margin-right: 10px;
                margin-top: -1px;
            }

            .ss-event-filter-search {
                margin-top: 25px;

            }
        }
        .date-type-and-period-selector {
            display: flex;

            .time-select-row-wrapper {
                padding-left: 12px;
            }
        }

        .ss-event-filter-date-selector {
            margin-left: 12px;
        }

        .event-filter-switches {
            margin-left: 10px;
            color: black;
        }

        .event-filter-dropdowns {
            margin-left: 0px;
            margin-top: 20px;
            align-items: center;
            &.row {
                width: auto;
            }
        }

        .time-selector-row .time-selector-row-content {
            padding-left: 0px;
            justify-content: normal;

            .range-text {
                float: none;
                display: block;
                align-items: center;
            }
        }

        .event-types {
            display: flex;
            padding-top: 10px;
            width: fit-content;

            .event-type {
                @include create-border($silver);
                padding: 1px;
                height: 24px;
                background-color: white;
                width: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 6px;
                cursor: pointer;

                &.selected {
                    @include create-border($dodger-blue);
                    background-color: $pattens-blue;
                }
            }

            .tippy-content {
                .container {
                    .event-list-filter-tippy-label {
                        color: black;
                    }
                }

                .event-list-filter-tippy-title {
                    @include font-bold-text-md;
                    line-height: 16px;
                    color: black;
                }
            }
        }

        .status-label {
            margin-bottom: 5px;
        }

        .time-label {
            margin-left: -13px;
        }

        #status-ddn {
            width: 200px;
        }

        .timezone-ddn {
            width: 80px;
            margin-top: 5px;
        }

        .cause-of-event-ddn {
            width: 300px;
        }

        .event-list-switch {
            padding: 5px 10px;
            margin-top: 10px;
            align-self: center;
            display: flex;

            .event-list-control-label {
                padding: 2px 5px;
            }
        }

        .event-filter-drive-selector {
            margin: 5px 0px;

            .drive-selector-label {
                display: flex;

                .info-icon-wrapper {
                    padding: 1px 5px;
                }
            }

            .drive-selector-dropdown {
                margin-top: 10px;
                max-width: 500px;

                .general-select__menu {
                    width: calc(100% - 25px);

                    .general-select__option {
                        cursor: pointer;

                        .drive-selector-option {
                            display: flex;

                            label {
                                margin-left: 5px;
                                margin-top: 1px;
                                cursor: pointer;
                                word-break: break-all;
                            }
                        }

                        &.general-select__option--is-disabled {
                            cursor: not-allowed;

                            label {
                                cursor: not-allowed;
                            }
                        }

                    }
                }

                .general-select__value-container {
                    flex-direction: row-reverse;
                    color: black;
                }
            }

            .general-select--is-disabled {
                .general-select__control--is-disabled {
                    .general-select__value-container {
                        color: $silver-chalice;
                    }
                }
            }
        }
    }

    .info-icon-wrapper {
        width: 24px;
        cursor: pointer;
        padding: 2px 5px;
        >svg{
            display:block;        }
    }

    .event-type-filter-info-tippy {
        @include font-regular-text-md;
        width: fit-content;
    }
}
