@use "~theme/colors" as *;
@use '~theme/mixins' as *;
@use '~theme/typography' as *;
@use "~theme/breakpoints" as *;

#trend-container .measurement-list-container {
    
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin: 0px;
    .signal-block {
        position: relative;
    }
    .measurement-list {
        // @include create-border;
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: $white;
        padding: 16px 0px;
        margin: 6px 0;

        .measurement-list-title {
            @include font-medium-text-lg;
            color: $nero;
            line-height: 24px;
            // padding-left: 8px;
            padding-bottom: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            svg {
                margin: 0 10px 0 10px;
                cursor: pointer;
            }
        }
        .measurement-list-buttons {
            display: flex;
        }
        .signal-group-header-info {
            display: flex;
            color: $dove-gray;
            font-size: 13px;
            span {
                padding-right: 10px;
                padding-top: 5px;
            }
        }
        .measurement-list-head {
            & .measurement-list-item { 
                @include font-medium-text-md;
                border-bottom: 1px solid $nero;
                color: $dove-gray;
                &:hover {
                    cursor: auto;
                    background-color: $white;
                }
            }
            & .data-min-max, & .data-average, & .data-value {
                position: relative;
                right: 8px;
            }
        }
        .measurement-list-body {
            overflow: auto;
            position: relative;
            max-height: calc(var(--vh) * 100 - 365px);
        }
        .measurement-list-item {
            @include font-regular-text-md;
            @include shadow-inset-alto;
            display: flex;
            align-items: center;
            padding: 12px 8px;
            line-height: 18px;
            
            &:hover {
                background-color: $concrete;
                cursor: pointer;
            }

            &.selected-measurement-list-item {
                background-color: $light-blue;
            }
            .data-command-checkbox{
                width: 6%;
                svg{
                    padding: 0px,16px,0px,16px;
                }
            }

            .condition-icon-label {
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
            .condition-icon {
                width: 16px;
                margin-left: 0;
                margin-right: 10px;
            }

            .data-min-max {
                width: 20%;
            }
            .data-average {
                width: 20%;
            }
            .data-label {
                display: flex;
                width: 40%;

                .health-icon {
                    align-self: center;

                    svg {
                        margin: 0 8px 0 8px;
                    }
                }
                &__unit {
                    color: $dove-gray;
                    border-left: 2px solid rgba(0,0,0,0.12);
                    padding-left: 8px;
                    margin-left: 20px;
                    margin-right: 30px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: flex-start;
                }
            }

            .data-value {
                overflow-wrap: break-word;
                width: 20%;
            }
            .data-command {
                text-align: right;
                width: 10%;
                margin-left: auto;

                >span {
                    margin: 0 10px 0 10px;
                }

                svg {
                    cursor: pointer;
                }

                .tippy-popper {
                    text-align: left;
                }
            }
            // .d-none class is used to hide the element, needs to be removed after PT-23 release
            .d-none {
                display: none;
            }
        }
        &.measurement-list-capability {
            & .measurement-list-title {
                border-bottom: 1px solid $nero;
            }
            .data-value {
                width: 45%;
            }
            .data-value {
                width: 25%;
                text-align: left;
            }
            .data-command {
                width: 30%;
            }
        }
    }

    @include customScreenMaxWidth(1440) {
        width: 100%;
        .measurement-list {
            width: 100%;
            height: initial;
        }
    }

    @include customScreenMaxWidth(1390) {
        .measurement-list {
            flex-basis: 100%;
        }
    }

    @include customScreenMaxWidth(1460) {
        .measurement-list {
            .measurement-list-item {
                .data-label {
                    width: 40%;
                }
                .data-min-max {
                    width: 15%;
                }
                .data-average {
                    width: 15%;
                }
                .data-value {
                    width: 20%;
                }

                .data-command {
                    width: 10%;
                }
            }
        }
        .measurement-list-capability {
            .measurement-list-item {
                .data-label {
                    width: 45%;
                }
                .data-value {
                    width: 25%;
                }

                .data-command {
                    width: 35%;
                }
            }
        }
    }
}
