@use "~theme/colors" as *;
@use "~theme/mixins" as *;

.cross-asset-analysis-container {
    @include standard-tabContent-layout;

    .cross-asset-analysis-content {
        position: relative;
        margin-left: 0;
        margin-right: 0;
        width: 100%;

        .tab-container {
            background-color: $wild-sand;

            .tab-content {
                height: inherit;
                overflow-y: unset;
                position: relative;
            }

            .tab:first-of-type.selected {
                border-left: none;
            }
        }
    }

    .cross-asset-analysis-tab-container {
        @include create-border;
        @include standard-tab-container-spacing;
        overflow-y: auto;
        width: 100%;

        #popout-chart-row-button-container {

            .left-button-group .unique-y-scale {
                padding-left: 16px;
            }
        }

        .button-row-container {
            padding-top: 24px;
        }

        .chart-container {
            .highcharts-no-data {
                height: 310px;
            }
        }

        .selection-info-message {
            margin-top: 10px;
            height: 250px;
        }
    }
}

#cross-analysis-scatter-chart-pop-out,
#cross-analysis-trends-chart-pop-out,
#cross-analysis-histogram-chart-pop-out {
    .remove-button {
        display: none;
    }
}

#cross-analysis-trends-chart-pop-out {
    .highcharts-no-data {
        background: transparent;
    }
}