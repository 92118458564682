@use '~theme/typography' as *;
@use '~theme/_colors' as *;

.notification-groups-container {
    .notification-groups-header {
        display: flex;
        height: 40px;
        align-items: center;
        border-bottom: 1px solid black;

        .header-item {
            display: flex;
            color: $dove-gray;
            @include font-medium-text-md;

            &.notification-type {
                margin-left: 15px;
                min-width: 200px;
                width: 30%;
            }

            &.channels {
                width: 70%;
            }
        }
    }

    .notification-groups-list {
        display: grid;

        .expandable-component {
            .header {
                height: 40px;
                background-color: $gallery;
                border-bottom: 1px solid $silver;
                @include font-medium-text-md;
                font-size: 16px;
                width: 100%;
                text-align: left;

                .open-close-container {
                    margin: 0px 15px;
                }

                .title-content {
                    button {
                        line-height: 30px;
                        border: none;
                        background-color: transparent;
                        padding: 0 6px;
                    }

                    .tippy-content {
                        @include font-regular-text-md;
                    }
                }
            }
        }

        .notification-type-row {
            display: flex;
            min-height: 40px;
            align-items: center;
            border-bottom: 1px solid $gallery;

            .notification-type-channels-group {
                display: flex;
                width: 70%;
            }

            .notification-type-col {
                display: flex;
                width: 100%;
                align-items: center;
                @include font-regular-text-md;
                padding: 5px 0px;

                &.type-name {
                    width: 30%;
                    min-width: 200px;
                    padding-left: 15px;
                    margin-right: 10px;
                }

                &.channel {
                    padding-right: 10px;
                }

                .switch-label {
                    padding-left: 10px;
                }
            }
        }
    }
}

.copy-notification-settings-modal {
    .modal-content {
        width: 650px;
        min-width: 600px;
        min-height: 400px;

        .modal-header {
            margin-bottom: 5px;

            .general-dialog-title {
                display: flex;
                align-items: center;
                gap: 5px;
                font-size: 20px !important;
            }

            .btn-close {
                font-size: 12px;
            }
        }

        .modal-body {
            border-bottom: 1px solid $alto !important;

            .settings-to-copy {
                label {
                    font-family: 'ABBvoice-Bold';
                    margin-bottom: 4px;
                }

                .copy-setting-options {
                    display: flex;
                    gap: 20px;
                }
            }

            .copy-organizations-list {
                label {
                    font-family: 'ABBvoice-Bold';
                    margin: 5px 0 7px;
                }

                .search-organizations {
                    height: 35px;

                    .search-field {
                        width: 100%;
                        border-radius: 6px;
                    }
                }

                .organization-list {
                    min-height: 200px;
                    max-height: 400px;
                    margin: 10px 5px 5px;
                    overflow: auto;

                    .copy-organization-list {
                        display: flex;
                        align-items: center;
                        overflow-wrap: break-word;
                        margin-top: 5px;
                        gap: 5px;

                        &.select-all {
                            border-bottom: 1px solid $alto;
                            padding-bottom: 3px;
                        }

                        .custom-checkbox {
                            & .checkbox {
                                border-radius: 8px;
                            }
                        }
                    }
                }
            }

            .organization-item {
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                gap: 5px;
                overflow-wrap: break-word;

                .organization-title {
                    max-width: 450px;
                    cursor: pointer;
                }
            }

            .no-matches-found {
                margin-top: 10px;
                font-size: 14px;
                color: #a0a0a0;
            }
        }

        .modal-footer {
            margin-top: 5px;
        }
    }
}