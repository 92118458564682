@use '~theme/typography'as *;
@use '~theme/_colors'as *;

.notification-settings {
    .user-profile-settings-content-header {
        display: flex;
        justify-content: space-between;
        .copy-notification-settings {
            display: flex;
            align-items: center;
            gap: 5px;
            cursor: pointer;
            .copy-settings-label {
                font-family: "ABBvoice-Medium";
                font-size: 16px;
            }
        }
    }
    .notification-settings-tabs {
        display: flex;
        height: 35px;
        margin-top: 15px;
        @include font-medium-text-md;

        .notification-settings-tab {
            border: none;
            padding: 0px;
            margin-left: 16px;
            margin-right: 20px;
            background-color: white;
            border-bottom: 2px solid white;

            &.selected {
                border-bottom: 2px solid $dodger-blue;
            }
        }
    }

    .loading-wrapper {
        position: relative;
        min-height: 400px;
    }

    .notification-channels-container {
        margin: 16px;

        .notification-channels-section {
            margin-top: 15px;
            margin-bottom: 20px;

            .title {
                @include font-medium-text-md;
                color:black;
            }

            .description {
                @include font-regular-text-md;
                font-size: 12px;
                color: $dove-gray;
            }

            .radio-button {
                height: 30px;
                width: fit-content;

                .radio-button-label {
                    width: fit-content;

                    .radio-button-label-text {
                        max-width: fit-content;
                    }
                }
            }

            .notification-channel-list {
                display: flex;
                margin-top: 10px;

                .channel-info-item {
                    width: 100%;
                    background-color: $wild-sand;
                    margin-right: 10px;
                    padding: 10px;
                    border-radius: 7px;

                    .channel-info-header {
                        display: flex;
                        @include font-bold-text-md;
                        color:black;

                        div {
                            margin-right: 10px;
                        }
                    }

                    .channel-info-description {
                        @include font-regular-text-md;
                        color: $dove-gray;
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}