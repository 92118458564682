@use '~theme/colors' as *;
@use '~theme/breakpoints' as *;
@use '~theme/mixins' as *;

.internal-tabs-operational-parameters {
    .tab-container {
        .tab-content {
            max-height: calc(var(--vh) * 100 - 210px) !important;
        }
        .tab {
            border-right: 0 !important;
        }
    }

    .measurement-list-container {
        .measurement-list-body {
            max-height: calc(var(--vh) * 100 - 335px) !important;
        }
    }

    .tab-container {
        background-color: $white !important;
    }
}